body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@font-face {
	font-family: 'Bianco Serif';
	src: url('./fonts/Bianco\ Serif\ Regular/BiancoSerif-Regular.eot');
	src: url('./fonts/Bianco\ Serif\ Regular/BiancoSerif-Regular.eot?#iefix')
			format('embedded-opentype'),
		url('./fonts/Bianco\ Serif\ Regular/BiancoSerif-Regular.ttf')
			format('truetype'),
		url('./fonts/Bianco\ Serif\ Regular/BiancoSerif-Regular.svg') format('svg'),
		url('./fonts/Bianco\ Serif\ Regular/BiancoSerif-Regular.woff')
			format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Moderat Bold';
	src: url('./fonts/Moderat/Moderat-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Moderat Regular';
	src: url('./fonts/Moderat/Moderat-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roobert Semi Bold';
	src: url('./fonts/Roobert/Roobert-SemiBold.eot');
	src: url('./fonts/Roobert/Roobert-SemiBold.eot?#iefix')
			format('embedded-opentype'),
		url('./fonts/Roobert/Roobert-SemiBold.woff2') format('woff2'),
		url('./fonts/Roobert/Roobert-SemiBold.woff') format('woff'),
		url('./fonts/Roobert/Roobert-SemiBold.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Blacker Pro Text Condensed';
	src: url('./fonts/BlackerPro/Blacker-Pro-Text-Condensed-Medium.otf');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roobert Bold';
	src: url('./fonts/Roobert/Roobert-Bold.eot');
	src: url('./fonts/Roobert/Roobert-Bold.eot?#iefix')
			format('embedded-opentype'),
		url('./fonts/Roobert/Roobert-Bold.woff2') format('woff2'),
		url('./fonts/Roobert/Roobert-Bold.woff') format('woff'),
		url('./fonts/Roobert/Roobert-Bold.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roobert Heavy';
	src: url('./fonts/Roobert/Roobert-Heavy.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roobert Regular';
	src: url('./fonts/Roobert/Roobert-Regular.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roobert Medium';
	src: url('./fonts/Roobert/Roobert-Medium.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roobert Light';
	src: url('./fonts/Roobert/Roobert-Light.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roobert Light Italic';
	src: url('./fonts/Roobert/Roobert-LightItalic.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roobert Regular Italic';
	src: url('./fonts/Roobert/Roobert-RegularItalic.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roobert Medium Italic';
	src: url('./fonts/Roobert/Roobert-MediumItalic.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roobert Semi Bold Italic';
	src: url('./fonts/Roobert/Roobert-SemiBoldItalic.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roobert Bold Italic';
	src: url('./fonts/Roobert/Roobert-BoldItalic.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roobert Heavy Italic';
	src: url('./fonts/Roobert/Roobert-HeavyItalic.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Archivo Black';
	src: url('./fonts/Archivo/Archivo-Black.otf') format('opentype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Placard Next Bold';
	src: url('./fonts/PlacardNext/PlacardNextBold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Placard Next Medium';
	src: url('./fonts/PlacardNext/PlacardNextMedium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Placard Next Regular';
	src: url('./fonts/PlacardNext/PlacardNextRegular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Placard Next Condensed Regular';
	src: url('./fonts/PlacardNext/PlacardNextCondRg.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Placard Next Condensed Medium';
	src: url('./fonts/PlacardNext/PlacardNextCnMed.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Placard Next Condensed Bold';
	src: url('./fonts/PlacardNext/PlacardNextCnBold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Placard Next Condensed Light';
	src: url('./fonts/PlacardNext/PlacardNextCondLt.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Placard Next Light';
	src: url('./fonts/PlacardNext/PlacardNextLight.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Roboto Bold';
	src: url('./fonts/Roboto/Roboto-Bold-webfont.eot');
	src: url('./fonts/Roboto/Roboto-Bold-webfont.eot?#iefix')
			format('embedded-opentype'),
		url('./fonts/Roboto/Roboto-Bold-webfont.ttf') format('truetype'),
		url('./fonts/Roboto/Roboto-Bold-webfont.svg') format('svg'),
		url('./fonts/Roboto/Roboto-Bold-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'AT Surt DemiBold';
	src: url('./fonts/Surt/Surt\ Demi\ Bold/Surt-DemiBold.eot');
	src: url('./fonts/Surt/Surt\ Demi\ Bold/Surt-DemiBold.eot?#iefix')
			format('embedded-opentype'),
		url('./fonts/Surt/Surt\ Demi\ Bold/Surt-DemiBold.otf') format('opentype'),
		url('./fonts/Surt/Surt\ Demi\ Bold/Surt-DemiBold.woff') format('woff'),
		url('./fonts/Surt/Surt\ Demi\ Bold/Surt-DemiBold.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'AT Surt Medium';
	src: url('./fonts/Surt/Surt\ Medium/Surt-Medium.eot');
	src: url('./fonts/Surt/Surt\ Medium/Surt-Medium.eot?#iefix')
			format('embedded-opentype'),
		url('./fonts/Surt/Surt\ Medium/Surt-Medium.otf') format('opentype'),
		url('./fonts/Surt/Surt\ Medium/Surt-Medium.woff') format('woff'),
		url('./fonts/Surt/Surt\ Medium/Surt-Medium.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'AT Surt SemiBold';
	src: url('./fonts/Surt/Surt\ Semi\ Bold/Surt-SemiBold.eot');
	src: url('./fonts/Surt/Surt\ Semi\ Bold/Surt-SemiBold.eot?#iefix')
			format('embedded-opentype'),
		url('./fonts/Surt/Surt\ Semi\ Bold/Surt-SemiBold.otf') format('opentype'),
		url('./fonts/Surt/Surt\ Semi\ Bold/Surt-SemiBold.woff') format('woff'),
		url('./fonts/Surt/Surt\ Semi\ Bold/Surt-SemiBold.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Product Sans Regular';
	src: url('./fonts/Product\ Sans/Product-Sans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Product Sans Bold';
	src: url('./fonts/Product\ Sans/Product-Sans-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Product Sans Italic';
	src: url('./fonts/Product\ Sans/Product-Sans-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Product Sans Medium';
	src: url('./fonts/Product\ Sans/Product-Sans-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Product Sans Bold Italic';
	src: url('./fonts/Product\ Sans/Product-Sans-Bold-Italic.ttf')
		format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Product Sans Light';
	src: url('./fonts/Product\ Sans/Product-Sans-Thin.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'TTInterfaces Demibold';
	src: url('./fonts/TTInterfaces/TTInterfaces-Demibold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'TTInterfaces Medium';
	src: url('./fonts/TTInterfaces/TTInterfaces-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'TTInterfaces Regular';
	src: url('./fonts/TTInterfaces/TTInterfaces-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'TTInterfaces Bold';
	src: url('./fonts/TTInterfaces/TTInterfaces-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
